<template>
  <div class="refrence_warp">
    <div class="top">
      <div class="left">
        <div class="head">
          <div class="avatar">
            <img src="@/assets/bg-头像@2x.png"
                 alt="">
          </div>
          <div class="text">
            <span><span>{{info.nickname}}</span></span>
            <span style="padding :0 20rem">|</span>
            <span>{{info.gradeName}}</span>
          </div>
        </div>
        <div class="shuju">
          学习数据统计
        </div>
        <div class="number">
          <div class="item">
            <div class="m">
              {{info.totalPointsWon}}
            </div>
            <div class="n">
              总得分率(%)
            </div>
          </div>

          <div class="item">
            <div class="m">
              {{info.totalPaper}}
            </div>
            <div class="n">
              总提交试卷
            </div>
          </div>
          <div class="item">
            <div class="m">
              {{info.questions}}
            </div>
            <div class="n">
              完成题目
            </div>
          </div>

        </div>
      </div>
      <!-- 图表，知识点曲线 -->
      <div class="right">
        <quxian ref="quxianRefs" />
      </div>
    </div>
    <div class="bottom">

      <div class="vip">
        <div class="vip_head">
          <div class="vip_left">

            <img src="@/assets/home/Rectangle.png"
                 style="width: 160rem;
height: 64rem;"
                 alt="">
            <div class="text">我的特权</div>
            <div class="text1">平台版</div>
            <!-- <div class="text2" @click="toBuy()">充值</div> -->

          </div>
        </div>
        <div class="vip_bottom">
          <div class="vip_main">
            <div class="date">{{report_end_time}}</div>
          </div>
        </div>
      </div>
      <div class="top_warp">
        <div class="tabs">
          <div class="line">
            <div class="tab"
                 v-if="this.userInfo.edu_grade_id == 1"
                 @click="toPage('/group_test')">
              <img src="@/assets/index/zujuan.png"
                   alt="">
              组卷测试
            </div>
            <div class="tab"
                 v-if="this.userInfo.edu_grade_id !== 1"
                 @click="toPage('/wholepaper')">
              <img src="@/assets/index/zhengjuan.png"
                   alt="">
              整卷测试
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quxian from './components/quxian.vue'
import { getStudentDetail } from '@/api/analysis.js'
import { getOneDayTime } from '@/utils/http.js'
import { nowDateTime } from '@/utils/date.js'
import { getVipInfo } from '@/api/vip.js'

export default {
  data () {
    return {
      indicator: [
        //修改文字颜色 #37A0F4
      ],
      xValues: [],
      yValues: [],
      info: {},
      this_week: [],
      last_week: [],
      upNumber: '',
      last: '#E5E5E5',
      week: '#37A0F4',
      myChart: undefined,
      autoModalFlag: true,
      path: '',
      report_end_time: ''
    }
  },
  components: {
    quxian
  },
  created () {

    getVipInfo().then(res => {
      let data = res.data
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      if (data.vip_end_time != '0000-00-00 00:00:00') {
        userInfo.vip_end_time = data.vip_end_time
      }

      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
    })
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
  mounted () {

    this.getVipTime()
    this.getInfo()
    getOneDayTime()
  },
  methods: {
    toPage (path) {

      if (this.userInfo.vip_end_time == '' || this.userInfo.vip_end_time == '0000-00-00 00:00:00') {
        this.$http({
          url: '/api/v1/cp/check_do_paper',
          method: 'get'
        }).then(res => {
          if (res.data.do_paper) {
            this.path = path
            this.$router.push(path)
          } else {
            this.$message.info("不能做卷，请先开通vip权益")
          }
        })
      } else {
        this.path = path
        this.$router.push(path)
      }

    },
    toBuy () {
      this.$router.push('/buyVip')
    },
    getVipTime () {

      const currentTime = nowDateTime();

      this.$http({
        url: '/api/v1/user/buy_order_time',
        method: 'get'
      }).then(res => {

        if (this.userInfo.vip_end_time > currentTime && this.userInfo.vip_end_time > res.data.report_end_time) {
          this.report_end_time = this.userInfo.vip_end_time
        } else {
          this.report_end_time = res.data.report_end_time == '0000-00-00 00:00:00' ? '尚未开通VIP特权' : res.data.report_end_time
        }
      })
    },
    async getInfo () {
      // this.$http({
      //   url: '/api/v1/report/get_student_detail',
      //   method: 'get'
      // }).then(res => {
      //   this.indicator = []
      //   this.info = res.data

      //   this.info.this_week_question_num.forEach(item => {
      //     let subject = {
      //       name: item.subject_name,
      //       color: ''
      //     }
      //     this.this_week.push(item.num)
      //     this.indicator.push(subject)
      //   })

      //   this.info.last_week_question_num.forEach(item => {
      //     this.last_week.push(item.num)
      //   })
      //   this.drawLine()
      //   this.removeSymbol()
      // })
      const { data } = await getStudentDetail()
      this.indicator = []
      this.info = data
      this.info.thisWeekQuestionNum.forEach(item => {
        let subject = {
          name: item.subjectName,
          color: ''
        }
        this.this_week.push(item.num)
        this.indicator.push({ 'text': item.subjectName })
      })

      this.info.lastWeekQuestionNum.forEach(item => {
        this.last_week.push(item.num)
      })
      this.drawLine()
      // this.removeSymbol()


    },
    removeSymbol () {
      setTimeout(() => {
        let progress = document.getElementsByClassName('el-progress__text')
        for (let index = 0; index < progress.length; index++) {
          progress[index].innerHTML = progress[index].innerHTML.slice(0, progress[index].innerHTML.length - 1)
        }
      }, 200);
    },
    drawLine () {
      this.myChart = this.$echarts.init(document.getElementById('leida'), null, { renderer: 'svg' })
      //  
      this.myChart.setOption({
        tooltip: {
          show: true,
          trigger: 'yxis'
        },
        // legend: {
        //   itemWidth: 500,
        //   itemHeight: 12,
        //   left: '15%',
        //   top: 0
        // },
        tooltip: {
          trigger: 'item'
        },
        radar: {
          indicator: this.indicator,
          triggerEvent: true, // 开启雷达图的边角名称可点击
          radius: '70%',
          shape: 'circle',
          name: {
            //formatter: '{value}',
            fontSize: '22rem'
          },
          nameGap: 15, //指示器名称和指示器轴的距离。
          // 雷达线类型
          splitLine: {
            lineStyle: {
              type: 'dashed',
            }
          },
          // 背景颜色，雷达区域
          splitArea: {
            areaStyle: {
              color: ['#FFFFFF',],
            }
          },
        },
        triggerEvent: true,
        //雷达边框颜色
        color: ['rgba(166,166,166,0.7)', '#37A0F4',],
        series: [{
          type: 'radar',
          data: [{
            value: this.last_week,
            areaStyle: {
              normal: {
                color: this.last, // 选择区域颜色
              }
            },
            name: "上周做题量"
          },
          {
            value: this.this_week,
            areaStyle: {
              normal: {
                color: this.week, // 选择区域颜色
              }
            },
            name: "本周做题量"
          }
          ]
        }],
      })

      //雷达的点击事件
      this.myChart.on('click', (params) => {
        if (params.componentType === 'radar') {
          // 点击到了 radar坐标轴标签 上
          console.log(params);
          var asd = this.info.thisWeekQuestionNum.findIndex(item => {
            return item.subjectName == params.name
          })
          if (asd != -1) {
            this.upNumber = this.info.thisWeekQuestionNum[asd].num
            for (let index = 0; index < this.indicator.length; index++) {
              this.indicator[index].color = ''
            }
            this.indicator[asd].color = '#37A0F4'
            this.clear()
            this.drawLine()
          } else {
            this.upNumber = ''
          }
        } else if (params.componentType === 'series') {

        }
      })

      window.addEventListener('resize', this.resizeChart)

    },
    resizeChart () {
      // let myChart = this.$echarts.init(document.getElementById('leida'))
      this.myChart.resize()
    },
    clear () {
      if (this.myChart) {
        this.myChart.dispose()
      }
      // this.drawLine()
    },
    toLeida () {
      this.$router.push('/study_analysis')
    },
    getProgressBakcground (item) {
      if (Number(item.contrast) > 0) {
        return '#37A0F4'
      } else {
        return '#F67878'
      }
    },
    getMath (item) {
      // if (item.contrast < 0) {
      //   return 0
      // }
      return Math.abs(item.contrast)
    },
    checkThisWeek () {
      this.week = '#37A0F4'
      this.last = '#E5E5E5'
      this.clear()
      this.drawLine()
    },
    checkLastWeek () {
      this.week = '#E5E5E5'
      this.last = '#37A0F4'
      this.clear()
      this.drawLine()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  }
}
</script>

<style lang="scss" scoped>
.refrence_warp {
  // height: 100vh;
  // overflow: scroll;
  background: #f6f6f6;
  .top {
    display: flex;
    height: 400rem;
    margin: 45rem 40rem 40rem 40rem;

    font-size: 24rem;
    .left {
      flex-shrink: 0;
      width: 600rem;
      background-image: url(../../assets/bg-学情分析@2x.png);
      background-size: 100% 100%;
      height: 100%;
      margin-right: 40rem;
      background-repeat: no-repeat;
      .head {
        margin: 30rem;
        height: 174rem;
        display: flex;
        .avatar {
          width: 70rem;
          height: 70rem;
          margin-right: 11rem;
          img {
            width: 70rem;
            height: 70rem;
          }
        }
        .text {
          flex: 1;
          color: white;
          font-size: 24rem;
          font-weight: bold;
          margin-left: 20rem;
          span {
            line-height: 53rem;
            &:nth-child(1) {
              flex: 1;
              display: inline-block;
              vertical-align: middle;
              max-width: 278rem;
              overflow: hidden;
              word-break: break-word;
              max-height: 172rem;

              span {
                width: 100%;
                height: 100%;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            &:nth-child(2) {
              width: 40rem;
              text-align: center;
            }
            &:nth-child(3) {
              flex: 1;
            }
          }
        }
      }
      .shuju {
        // margin-top: 89rem;
        margin-left: 30rem;

        font-size: 24rem;
        font-weight: bold;
        color: #ffffff;
      }
      .number {
        display: flex;
        margin-top: 32rem;
        .item {
          flex: 1;
          .m {
            font-size: 36rem;
            font-weight: bold;
            color: #ffffff;
            line-height: 27rem;
            // text-indent: 50rem;
            text-align: center;
          }
          .n {
            text-align: center;
            font-weight: 500;
            color: #e1effa;
            margin-top: 23rem;
            font-size: 24rem;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      width: 1100rem;
      border-radius: 11rem;
      border: 1rem solid #ececec;
      background: #ffffff;
    }
  }
  .bottom {
    margin: 45rem 40rem 40rem 40rem;
    display: flex;
    gap: 40rem;
    .vip {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24rem;
      align-self: stretch;
      border-radius: 24rem;
      background: linear-gradient(
          180deg,
          rgba(253, 236, 201, 0.6) 0%,
          rgba(233, 195, 131, 0) 37.09%
        ),
        #fff;
      width: 600rem;
      height: 182rem;
      .vip_head {
        display: flex;
        padding-right: 32rem;
        align-items: center;
        gap: 16rem;
        align-self: stretch;

        .vip_left {
          display: flex;
          align-items: center;
          gap: -8rem;
          flex: 1 0 0;
          position: relative;
          justify-content: space-between;

          .text {
            color: var(--light-1-a-1-a-1-a, #1a1a1a);
            text-align: center;

            font-size: 24rem;
            font-style: normal;
            font-weight: 600;
            line-height: 36rem; /* 150% */
            position: absolute;
            top: 51%;
            left: 12%;
            transform: translate(-50%, -50%);
          }

          .text1 {
            color: var(--light-666666, var(--neutral-color-666666, #666));
            margin-left: -50rem;
            font-size: 20rem;
            font-style: normal;
            font-weight: 400;
            line-height: 30rem; /* 150% */
          }
          .text2 {
            color: var(--Brand-Color_6default, #2196f3);
            text-align: right;

            font-size: 24rem;
            font-style: normal;
            font-weight: 400;
            line-height: 36rem; /* 150% */
          }
        }
      }
      .vip_bottom {
        display: flex;
        padding: 0rem 32rem 32rem 32rem;
        align-items: flex-start;
        gap: 24rem;
        align-self: stretch;

        .vip_main {
          display: flex;
          padding: 16rem;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border-radius: 16rem;
          background: var(--light-fafafa, #fafafa);
          .date {
            overflow: hidden;
            color: var(--light-666666, var(--neutral-color-666666, #666));
            text-overflow: ellipsis;

            font-size: 20rem;
            font-style: normal;
            font-weight: 400;
            line-height: 30rem; /* 150% */
          }
        }
      }
    }
    .top_warp {
      width: 1100rem;
      display: flex;
      // padding: 32rem;
      border-radius: 24rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      height: 250rem;
      background: #fff;
      .tabs {
        width: 1036rem;

        ::-webkit-scrollbar {
          width: 10rem; //定义滚动条宽度
          height: 10rem; //定义滚动条高度
          border-radius: 10rem; //定义滚动条圆角
          background-color: rgba(240, 240, 240, 0.1); //定义滚动条颜色
        }

        /*定义滚动条轨道 内阴影+圆角*/
        ::v-depp .-webkit-scrollbar-track {
          box-shadow: inset 0 0 0rem rgba(240, 240, 240, 0.5); //定义轨道阴影
          border-radius: 10rem; //定义轨道圆角
          background-color: rgba(4, 56, 114, 0.5); //定义轨道颜色
        }

        /*定义滑块 内阴影+圆角*/
        ::-webkit-scrollbar-thumb {
          border-radius: 10rem; //定义滑块圆角
          box-shadow: inset 0 0 0rem rgba(240, 240, 240, 0.5); //定义滑块阴影
          background-color: rgba(2, 33, 54, 0.5); //定义滑块颜色
        }
        .line {
          display: flex;
          justify-content: flex-start;
          overflow: hidden;
          margin-left: 4vw;
          margin-right: 4vw;
          // width: inherit;
          // width: 108%;

          .tab {
            width: 20%;
            // height: 5.625em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 24rem;
            font-weight: bold;
            color: #333333;
            img {
              width: 100rem;
              margin-bottom: 20rem;
            }
          }
        }
      }
      .tabs1 {
        width: 59.5vw;
        background: #ffffff;
        box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
        border-radius: 10rem;
        flex-shrink: 0;
        height: 380rem;
        margin-top: 3vh;
        overflow-y: hidden;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 50rem);
          margin-left: 30rem;
          // height: 59rem;
          margin-top: 30rem;
          margin-bottom: 20rem;
          span {
            &:nth-child(1) {
              font-weight: bold;
              color: #000000;
              font-size: 24rem;
            }
            &:nth-child(2) {
              font-weight: bold;
              color: #999999;
              font-size: 22rem;
              display: flex;
              align-items: center;
              img {
                width: 24rem;
                height: 24rem;
                margin-left: 14rem;
              }
            }
          }
        }
        .line_wrap {
          display: flex;
          overflow: auto;
          .item {
            flex-shrink: 0;
            width: 14.58vw;
            height: calc(100% - 83rem);
            margin-left: 30rem;
            .card {
              height: 162rem;
              background-image: url("../../assets/index/card_yuwen.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              overflow: hidden;
              .content {
                font-size: 22rem;
                font-weight: 800;
                height: 43rem;
                color: #ffffff;
                margin: 22rem 0 30rem 20rem;
              }
              .line {
                width: 52rem;
                height: 7rem;
                background: #ffffff;
                border-radius: 4rem;
                margin-left: 20rem;
                margin-bottom: 12rem;
              }
              .subject {
                margin-left: 20rem;
                font-size: 22rem;
                font-weight: 500;
                color: #ffffff;
              }
            }
            .name {
              font-size: 22rem;
              font-weight: bold;
              color: #333333;
              margin-top: 16rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .score_and_start {
              margin-top: 16rem;
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 15rem;
              span {
                font-weight: 500;
                font-size: 20rem;
                color: #999999;
              }
              img {
                width: 21rem;
              }
            }
          }
        }
      }
    }
  }
}

.yuan {
  pointer-events: none;
  width: 205rem;
  height: 205rem;
  border: 3rem solid #cfe6ff;
  border-radius: 50%;
  position: absolute;
  top: 79rem;
  left: 75rem;
}

::v-deep .el-progress-bar__outer {
  height: 10rem !important;
}
::v-deep .el-progress-bar__outer {
  background: #e5e5e5 !important;
}
// .xia {
//   color: rgba(255, 97, 71, 1);
// }
// .ping {
//   background: #37a0f4;
// }
// .shang {
//   background: #37a0f4;
// }
.progress {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}
.my_progress {
  background: #e5e5e5;
  border-radius: 10rem;
  height: 18rem;
  width: 85%;
}
.desc {
  text-align: center;
  text-indent: 15rem;
  color: #37a0f4;
  height: 20rem;
  font-size: 22rem;
}
</style>